import { StaticProvider } from '@angular/core';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from '@environment';
import { UCEnvironment, UCOutage } from '@shared/environment-definition';
import { scriptLoader } from '@shared/helpers/scriptLoader';

import { AppModule } from './app/app.module';

const headers = { Pragma: 'no-cache', 'Cache-Control': 'no-cache' };

(async () => {
  const response = await Promise.all([
    fetch('/assets/envConfigs/config.json', { headers }),
    fetch('/assets/envConfigs/outage-mode.json', { headers }),
  ]);
  const config = (await response[0].json()) as UCEnvironment;
  const outage = (await response[1].json()) as UCOutage;

  Object.assign(environment, config, outage);

  scriptLoader(environment);

  Object.assign(window, { UCENV: environment });

  const staticProviders: StaticProvider[] = [{ provide: FIREBASE_OPTIONS, useValue: environment.firebase }];

  if (environment.googleAnalyticsKey) {
    staticProviders.push({
      provide: 'googleTagManagerId',
      useValue: environment.googleAnalyticsKey,
    });
  }

  platformBrowserDynamic(staticProviders)
    .bootstrapModule(AppModule)
    // eslint-disable-next-line no-console
    .catch((err) => console.log(err));
})();
